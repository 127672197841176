<script>
export default {
	_config: {"route": {"path": "detail", "meta": {"title": "查看"}}},
	data() {
		return {
			OSS_URL: process.env["VUE_APP_URL_OSS"] + 'common-icon/',
			input: '',
			dialogVisible: false,
			info: {
				"反馈人": "name",
				"学号": "student_no",
				"班主任": "teacher_name",
				"反馈校区": "school_name",
				"反馈年级": "grade_name",
				"行政班": "class_name",
				"用户类型": "role",
				"问题分类": "type"
			},
      colors: {
        "日常生活问题": "#E9E7FF",
        "校园霸凌": "#FFEAE7",
        "投诉举报": "#FFF8E7",
        "课堂问题": "#E7FFF2",
        "一对一辅导问题": "#FFF4E7",
        "直通校长": "#FEE7FF",
        "其他反馈": "#E7F1FF"
      },
      color: {
        "日常生活问题": "#755FF6",
        "校园霸凌": "#F05444",
        "投诉举报": "#F6CC64",
        "课堂问题": "#6ECFB3",
        "一对一辅导问题": "#F6A05D",
        "直通校长": "#F65DF3",
        "其他反馈": "#5D95F6"
      },
      infoData: {
				created_at: ''
			},
			reply: {
				id: this.$route.query.id,
				content: ''
			}
		}
	},
	created() {
		this.$_axios2.get('api/feedback/index/student-show', {params: {id: this.$route.query.id}}).then(res => {
			this.infoData = res.data.data
		})
	},
	methods: {
		onSubmit() {
			this.$_axios2.post('api/logistics/student-feedback/reply', this.reply, {logic: 1}).then(() => {
				this.$message.success('回复成功')
				this.$_axios2.get('api/feedback/index/student-show', {params: {id: this.$route.query.id}}).then(res => {
					this.infoData = res.data.data
					this.dialogVisible = false
					this.reply.content = ''
				})
			})
		}
	}
}
</script>

<template>
	<div style="padding-right: 24rem;box-sizing: border-box;background: #F7F7F7">
		<div class="top">
			<div v-for="(i,k) of info">
				<span class="label">{{ k }}</span>
				<span v-if="k !== '问题分类'" class="value">{{ infoData[i] }}</span>
				<span v-else :style="{color: color[infoData[i]],background: infoData[i]&&colors[infoData[i]],padding: '4rem 12rem',borderRadius: '8rem' }" class="value">{{ infoData[i] }}</span>
			</div>
		</div>

		<div class="content">
			<div style="display: flex;align-items: center;height: 54rem;">
				<img :src="OSS_URL+'look.png'" alt="">
				<div style="font-weight: 500;font-size: 20rem;color: #1E3AA2;">反馈内容</div>
			</div>
			<div style="width: 100%;height: 1rem;background: #DBDDE4"></div>
			<div style="display: flex;margin-top: 24rem;">
				<img :src="infoData.avator||OSS_URL+'feedback-avatar.png'" alt="" style="border: 1rem solid #1D2088;width: 40rem;height: 40rem;border-radius: 50%">
				<div>
					<span style="padding: 10rem 16rem;background: #F7F7F7;font-weight: 400;font-size: 14rem;color: #222222;display: inline-block;border-radius: 6rem">{{ infoData.content }}</span>
					<div style="font-weight: 400;font-size: 14rem;color: #888888;margin-top: 14rem;text-align: right">{{ infoData.created_at }}</div>
				</div>
			</div>
		</div>

		<div v-if="infoData.reply&&infoData.reply.length" class="content">
			<div style="display: flex;align-items: center;height: 54rem;">
				<img :src="OSS_URL+'info.png'" alt="">
				<div style="font-weight: 500;font-size: 20rem;color: #1E3AA2;">回复内容</div>
			</div>
			<div style="width: 100%;height: 1rem;background: #DBDDE4"></div>
			<div v-for="i in infoData.reply" style="display: flex;margin-top: 24rem;">
				<img :src="i.avator||OSS_URL+'feedback-avatar.png'" alt="" style="border: 1rem solid #1D2088;width: 40rem;height: 40rem;border-radius: 50%">
				<div>
					<div style="font-weight: 400;font-size: 14rem;color: #222;margin-bottom: 8rem;">{{ i.account_name }}</div>
					<div style="font-weight: 400;font-size: 14rem;color: #888888;margin-bottom: 14rem;">{{ i.created_at }}</div>
					<span style="padding: 10rem 16rem;background: #F7F7F7;font-weight: 400;font-size: 14rem;color: #222222;display: inline-block;border-radius: 6rem">{{ i.content }}</span>
				</div>
			</div>
		</div>

		<div v-if="infoData.reply&&infoData.reply.length<10" class="footer">
			<el-input v-model="reply.content" :autosize="{ minRows: 1, maxRows: 5}" maxlength="500" placeholder="请输入回复内容" style="border: none" type="textarea"></el-input>

			<div style="width: 100%;text-align: right">
				<el-button style="background: #326FFF;margin-top: 32rem;color: #FFF" @click="()=>{
					if(reply.content)
					 this.dialogVisible=true
					 else
					 this.$message.error('回复内容不能为空')
				}">发送
				</el-button>
			</div>
		</div>


		<el-button style="background: #326FFF;margin-top: 32rem;color: #FFF" @click="$router.back()">返回</el-button>

		<el-dialog :before-close="()=>dialogVisible=false" :visible.sync="dialogVisible" width="100rem">
			<div style="text-align: center;font-weight: 400;font-size: 18rem;color: #606266;margin-bottom: 48rem"><span>发送后无法撤回，请确认所回复内容是否无误？</span></div>
			<div style="text-align: center">
				<el-button type="primary" @click="onSubmit">保存</el-button>
				<el-button @click="dialogVisible = false">再看看</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<style lang="scss" scoped>
.top {
	align-items: center;
	background: #FFFFFF;
	border-radius: 8rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: 166rem;
	width: 100%;

	.label {
		color: #888888;
		display: inline-block;
		font-size: 18rem;
		font-weight: 400;
		margin-left: 24rem;
		margin-right: 40rem;
		min-width: 88rem;
	}

	.value {
		color: #3C3C3C;
		font-size: 18rem;
		font-style: normal;
		font-weight: 400;
	}
}

.content {
	background: #FFFFFF;
	border-radius: 8rem;
	box-sizing: border-box;
	margin-top: 24rem;
	min-height: 178rem;
	padding: 12rem;
	width: 100%;
}

img {
	height: 24rem;
	margin: 0 14rem;
	width: 24rem;
}

.footer {
	background: #FFFFFF;
	border-radius: 8rem;
	margin-top: 24rem;
	min-height: 144rem;
	padding: 24rem;
	width: 100%;
}

::v-deep .el-textarea__inner {
	border: none;
	resize: none;
}

::v-deep .el-dialog {
	width: 480rem !important;
}
</style>
